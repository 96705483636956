import React, {
  ForwardRefRenderFunction,
  HTMLAttributes,
  useState,
} from "react";
import styles from "./TextField.module.scss";
import InputMask from "react-input-mask";
import { FiEye, FiEyeOff } from "react-icons/fi";

interface Props extends HTMLAttributes<HTMLInputElement> {
  label: string;
  type?: string;
  error?: string;
  onChange?: (e: any) => void;
  disabled?: boolean;
  mask?: string;
}

const TextField: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  props,
  ref
) => {
  const {
    label,
    type = "text",
    error,
    onChange,
    disabled = false,
    mask = undefined,
    ...rest
  } = props;

  const [showPassword, setShowPassword] = useState(false);

  const inputType =
    type === "password" ? (showPassword ? "text" : "password") : type;

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={styles.inputGroup}>
      <label>{label}</label>

      <div className={styles.inputWrapper}>
        {mask ? (
          <InputMask
            type={inputType}
            onChange={onChange}
            inputRef={ref as React.RefObject<HTMLInputElement>}
            ref={ref as React.RefObject<InputMask>}
            disabled={disabled}
            mask={mask}
            {...rest}
            autoComplete="new-password"
            autoCorrect="off"
            spellCheck={false}
            data-form-type="other"
          />
        ) : (
          <input
            type={inputType}
            onChange={onChange}
            ref={ref as React.RefObject<HTMLInputElement>}
            disabled={disabled}
            {...rest}
            autoComplete="new-password"
            autoCorrect="off"
            spellCheck={false}
            data-form-type="other"
          />
        )}

        {type === "password" && (
          <button
            type="button"
            className={styles.passwordToggle}
            onClick={togglePasswordVisibility}
          >
            {showPassword ? <FiEyeOff /> : <FiEye />}
          </button>
        )}
      </div>

      {error && <small>{error}</small>}
    </div>
  );
};

export default React.forwardRef(TextField);
