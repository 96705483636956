import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Montserrat\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"500\",\"700\"],\"variable\":\"--font-montserrat\"}],\"variableName\":\"montserrat\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../scss/font.woff\",\"weight\":\"400\"},{\"path\":\"../scss/font.woff\",\"weight\":\"600\"}],\"variable\":\"--font-mirador\"}],\"variableName\":\"mirador\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-hot-toast/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Footer/Footer.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/GoogleTag/GoogleTag.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Header/Header.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/HeaderMobile/HeaderMobile.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/IdWall/IdWall.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/WhatsApp/WhatsApp.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/contexts/ModalContext.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/contexts/RecoilContext.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/scss/globals.scss")