import styles from "./Loading.module.scss";
import Loader from "./loader-alt.svg";
import Image from "next/image";

export default function Loading() {
  return (
    <div className={styles.Loading}>
      <Image
        src={Loader}
        width={16}
        height={16}
        alt="Loading"
        className={styles.spinner}
      />
    </div>
  );
}
