"use client";

import useAuth from "@/hooks/useAuth";
import Button from "@/components/Button";
import TextField from "@/components/TextField";
import styles from "./Secret.module.scss";
import { useForm, SubmitHandler } from "react-hook-form";

interface FormProps {
  secret: string;
}

interface SecretProps {
  onSuccess?: () => void;
}

export default function Secret({ onSuccess }: SecretProps) {
  const { handleSecret, loading } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormProps>();

  const submitForm: SubmitHandler<FormProps> = async (data) => {
    const res = await handleSecret({
      secret: data.secret,
    });

    if (res) {
      onSuccess?.();
    }
  };

  return (
    <div className={styles.secret}>
      <form className={styles.form} onSubmit={handleSubmit(submitForm)}>
        <div>
          <TextField
            label="Insira a secret key"
            type="password"
            {...register("secret", { required: true })}
            error={errors.secret && "Este campo é obrigatório"}
          />
        </div>
        <div className={styles.form__buttons}>
          <Button title="Enviar" loading={loading} />
        </div>
      </form>
    </div>
  );
}
