"use client";

import Link from "next/link";
import Image from "next/image";

import { FaFacebook } from "react-icons/fa6";
import { FiInstagram } from "react-icons/fi";

import styles from "./Footer.module.scss";
import Cookie from "../Cookie/Cookie";

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.footer__container}>
        <div className={styles.footer__grid}>
          <div className={styles.column}>
            <h3>Onde estamos</h3>
            <div className={styles.column__content}>
              <h5>SHOWROOM:</h5>
              <p>R. WISARD, 540</p>
              <p>VILA MADALENA – SP </p>
            </div>
            <div className={styles.column__content}>
              <h5>GALPÃO (ENTREGA E DEVOLUÇÃO):</h5>
              <p>AV. TORRES DE OLIVEIRA, 407</p>
              <p>JAGUARÉ – SP</p>
            </div>
          </div>

          <div className={styles.column}>
            <h3>Funcionamento</h3>
            <div className={styles.column__content}>
              <p>2ª a 6ª, 9h às 17h.</p>
              <p>Sáb 9h às 13h</p>
            </div>
          </div>

          <div className={styles.column}>
            <h3>Contato</h3>
            <div className={styles.column__content}>
              <Link prefetch={false} href={"mailto:contato@dfilipa.com.br"}>
                contato@dfilipa.com.br
              </Link>
              <p>(11) 3031-2999</p>
            </div>
            <div className={styles.column__content}>
              <p>Apenas WhatsApp</p>
              <p>(11) 99465-1299</p>
            </div>
          </div>

          <div className={styles.column}>
            <h3>Acompanhe</h3>
            <div className={styles.column__social}>
              <Link
                prefetch={false}
                href="https://www.instagram.com/dfilipa/"
                target="_blank"
                className={styles.linkSocial}
              >
                <FiInstagram />
                dfilipa
              </Link>
              <Link
                prefetch={false}
                href="https://www.facebook.com/dfilipalocacao/"
                target="_blank"
                className={styles.linkSocial}
              >
                <FaFacebook />
                dfilipalocacao
              </Link>
            </div>
          </div>
        </div>

        <div className={styles.copyright}>
          <p>
            Todas as imagens deste site são meramente ilustrativas. Proibida a
            reprodução total ou parcial sem a autorização prévia da D.Filipa.
            Direitos reservados e protegidos (Lei n. 9610, de 19.02.1998)
          </p>

          <div className={styles.policy}>
            {/* <Link prefetch={false}  href="/termos-de-uso">Termos de Uso</Link> */}
            <Link prefetch={false} href="/politica-de-privacidade">
              Política de Privacidade
            </Link>
          </div>

          <Link
            prefetch={false}
            href="https://alcance.digital/"
            className={styles.alcance}
            target="_blank"
          >
            <span>site feito por</span>
            <Image
              src="/images/alcance.svg"
              width={90}
              height={20}
              alt="Alcance Digital"
            />
          </Link>
        </div>
      </div>
      <Cookie />
    </footer>
  );
}
