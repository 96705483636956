import { ForwardRefRenderFunction, HTMLAttributes } from "react";
import styles from "./SelectForm.module.scss";
import { IoChevronDown } from "react-icons/io5";
import { Inter, Montserrat } from "next/font/google";
import React from "react";

interface Option {
  label: string;
  value: string;
}

interface Props extends HTMLAttributes<HTMLSelectElement> {
  label: string;
  options: Option[];
  onChange: (e: any) => void;
  error?: string;
}

const Select: ForwardRefRenderFunction<HTMLSelectElement, Props> = (
  props,
  ref
) => {
  const { label, options, onChange, error, ...rest } = props;

  return (
    <div className={styles.inputGroup}>
      <label>{label}</label>
      <select
        ref={ref}
        className={styles.Select__select}
        onChange={onChange}
        {...rest}
      >
        {options?.map((option: Option) => (
          <option
            key={`option-${option.value}`}
            value={option.value}
            className={styles.Select__option}
          >
            {option.label}
          </option>
        ))}
      </select>
      {error && <small>{error}</small>}
    </div>
  );
};

export default React.forwardRef(Select);
