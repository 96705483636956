"use client";

import Image from "next/image";
import TextField from "@/components/TextField/TextField";
import Button from "@/components/Button/Button";
import SelectForm from "@/components/SelectForm";
import { useForm, Controller } from "react-hook-form";
import { useState, useMemo } from "react";
import styles from "./IdWall.module.scss";
import useAuth from "@/hooks/useAuth";
import useData from "@/hooks/useData";
import { FormProps } from "@/@types";
import { BRAZILIAN_STATES } from "@/@constants/states";
import { toast } from "react-hot-toast";
import { usePathname } from "next/navigation";
import Loading from "@/components/Loading/Loading";
import Link from "next/link";
import Cookie from "js-cookie";
import Secret from "@/components/Secret/Secret";

const STEPS = {
  SECRET: 0,
  INITIAL: 1,
  PERSONAL_INFO: 2,
  ADDRESS: 3,
};

export default function IdWall() {
  const [step, setStep] = useState(() => {
    const secret = Cookie.get("secret");
    return secret ? STEPS.INITIAL : STEPS.SECRET;
  });
  const [loading, setLoading] = useState(false);
  const { checkUserExists, handleLoginViaDocument, handleRegister } = useAuth();
  const { getAddress } = useData();
  const pathname = usePathname();

  const minDate = useMemo(() => new Date().toISOString().split("T")[0], []);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<FormProps>({
    defaultValues: {
      cpf_cnpj: "",
      event_date: "",
      nome: "",
      sobrenome: "",
      telefone: "",
      email: "",
      password: "",
      endereco: {
        cep: "",
        logradouro: "",
        numero: "",
        complemento: "",
        bairro: "",
        cidade: "",
        estado: "",
      },
    },
  });

  const formatCpfCnpj = (value: string) => {
    const cleanValue = value.replace(/\D/g, "");
    return cleanValue.length <= 11
      ? cleanValue.replace(/^(\d{3})(\d{3})(\d{3})(\d{0,2}).*/, "$1.$2.$3-$4")
      : cleanValue.replace(
          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{0,2}).*/,
          "$1.$2.$3/$4-$5"
        );
  };

  const formatCep = (value: string) => {
    const cleanValue = value.replace(/\D/g, "");
    return cleanValue.replace(/^(\d{5})(\d{3}).*/, "$1-$2");
  };

  const handleStepSubmit = async (data: FormProps) => {
    setLoading(true);
    try {
      switch (step) {
        case STEPS.INITIAL:
          const userExists = await checkUserExists(data.cpf_cnpj);
          if (userExists) {
            await handleLoginViaDocument(data.cpf_cnpj, data.event_date || "");
          } else {
            setStep(STEPS.PERSONAL_INFO);
          }
          break;
        case STEPS.PERSONAL_INFO:
          setStep(STEPS.ADDRESS);
          break;
        case STEPS.ADDRESS:
          await handleRegister(data);
          break;
      }
    } catch (error) {
      console.error("Error in form submission:", error);
      toast.error("Ocorreu um erro. Por favor, tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  const [showAddressFields, setShowAddressFields] = useState(false);
  const [cepLoading, setCepLoading] = useState(false);

  const handleCepTyping = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedCep = formatCep(e.target.value);
    setValue("endereco.cep", formattedCep);

    if (formattedCep.replace(/\D/g, "").length === 8) {
      setCepLoading(true);
      try {
        const startTime = Date.now();
        const addressData = await getAddress(formattedCep);

        const elapsedTime = Date.now() - startTime;
        if (elapsedTime < 500) {
          await new Promise((resolve) =>
            setTimeout(resolve, 500 - elapsedTime)
          );
        }

        if (addressData) {
          setValue("endereco.logradouro", addressData.logradouro || "");
          setValue("endereco.bairro", addressData.bairro || "");
          setValue("endereco.cidade", addressData.localidade || "");
          setValue("endereco.estado", addressData.uf || "");
          setShowAddressFields(true);
          document.getElementById("numero")?.focus();
        }
      } catch (error) {
        console.error("Error fetching address:", error);
        toast.error("Erro ao buscar endereço. Verifique o CEP informado.");
      } finally {
        setCepLoading(false);
      }
    }
  };

  const handleBack = () => {
    if (step > STEPS.INITIAL) {
      setStep((prevStep) => prevStep - 1);
    }
  };

  const handleSecretSuccess = () => {
    setStep(STEPS.INITIAL);
  };

  const renderStepContent = () => {
    switch (step) {
      case STEPS.SECRET:
        return <Secret onSuccess={handleSecretSuccess} />;
      case STEPS.INITIAL:
        return (
          <>
            <Controller
              name="cpf_cnpj"
              control={control}
              rules={{ required: "CPF/CNPJ é obrigatório" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="text"
                  label="CPF/CNPJ"
                  placeholder="Digite seu CPF ou CNPJ"
                  onChange={(e) =>
                    field.onChange(formatCpfCnpj(e.target.value))
                  }
                  error={errors.cpf_cnpj?.message}
                />
              )}
            />
            <Controller
              name="event_date"
              control={control}
              rules={{
                required: "Data do evento é obrigatória",
                validate: (value) =>
                  (value && new Date(value) >= new Date(minDate)) ||
                  "A data do evento deve ser hoje ou no futuro",
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="date"
                  label="DATA DO EVENTO"
                  placeholder="Selecione a data do evento"
                  {...{ min: minDate }}
                  error={errors.event_date?.message}
                  style={{
                    colorScheme: "light",
                    cursor: "pointer",
                  }}
                />
              )}
            />
          </>
        );
      case STEPS.PERSONAL_INFO:
        return (
          <>
            <div className={styles.idwall__form_row}>
              <TextField
                label="Nome"
                {...control.register("nome", {
                  required: "Nome é obrigatório",
                })}
                error={errors.nome?.message}
                defaultValue={getValues("nome")}
              />
              <TextField
                label="Sobrenome"
                {...control.register("sobrenome", {
                  required: "Sobrenome é obrigatório",
                })}
                error={errors.sobrenome?.message}
                defaultValue={getValues("sobrenome")}
              />
            </div>
            <TextField
              label="Telefone"
              mask="(99) 99999-9999"
              {...control.register("telefone", {
                required: "Telefone é obrigatório",
              })}
              error={errors.telefone?.message}
              defaultValue={getValues("telefone")}
            />
            <div className={styles.idwall__form_row}>
              <TextField
                label="Email"
                type="email"
                {...control.register("email", {
                  required: "Email é obrigatório",
                })}
                error={errors.email?.message}
                defaultValue={getValues("email")}
              />
              <TextField
                label="Senha"
                type="password"
                {...control.register("password", {
                  required: "Senha é obrigatória",
                })}
                error={errors.password?.message}
                defaultValue={getValues("password")}
              />
            </div>
          </>
        );
      case STEPS.ADDRESS:
        return (
          <>
            <Controller
              name="endereco.cep"
              control={control}
              rules={{ required: "CEP é obrigatório" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="CEP"
                  placeholder="Digite o CEP"
                  onChange={(e) => {
                    field.onChange(formatCep(e.target.value));
                    handleCepTyping(e);
                  }}
                  error={errors.endereco?.cep?.message}
                  defaultValue={getValues("endereco.cep")}
                />
              )}
            />
            {cepLoading && (
              <div className={styles.loading}>
                <Loading />
              </div>
            )}
            {!cepLoading && showAddressFields && (
              <>
                <TextField
                  label="Logradouro"
                  {...control.register("endereco.logradouro", {
                    required: "Logradouro é obrigatório",
                  })}
                  error={errors.endereco?.logradouro?.message}
                  defaultValue={getValues("endereco.logradouro")}
                />
                <div className={styles.idwall__form_row}>
                  <TextField
                    label="Número"
                    id="numero"
                    {...control.register("endereco.numero", {
                      required: "Número é obrigatório",
                    })}
                    error={errors.endereco?.numero?.message}
                    defaultValue={getValues("endereco.numero")}
                  />
                  <TextField
                    label="Complemento"
                    {...control.register("endereco.complemento")}
                    defaultValue={getValues("endereco.complemento")}
                  />
                </div>
                <div className={styles.idwall__form_row_three}>
                  <TextField
                    label="Bairro"
                    {...control.register("endereco.bairro", {
                      required: "Bairro é obrigatório",
                    })}
                    error={errors.endereco?.bairro?.message}
                    defaultValue={getValues("endereco.bairro")}
                  />
                  <TextField
                    label="Cidade"
                    {...control.register("endereco.cidade", {
                      required: "Cidade é obrigatória",
                    })}
                    error={errors.endereco?.cidade?.message}
                    defaultValue={getValues("endereco.cidade")}
                  />
                  <SelectForm
                    label="Estado"
                    options={BRAZILIAN_STATES}
                    {...control.register("endereco.estado", {
                      required: "Estado é obrigatório",
                    })}
                    error={errors.endereco?.estado?.message}
                    defaultValue={getValues("endereco.estado")}
                  />
                </div>
              </>
            )}
          </>
        );
    }
  };

  const getProgressPercentage = () => {
    return ((step - 1) / (Object.keys(STEPS).length - 2)) * 100;
  };

  // return null if /showroom
  if (pathname === "/showroom") {
    return null;
  }

  return (
    <div className={styles.idwall}>
      <div className={styles.idwall__content}>
        <div className={styles.idwall__logo}>
          <Image
            src="/images/logo-dfilipa.svg"
            alt="DFilipa Logo"
            width={150}
            height={150}
            objectFit="contain"
          />
        </div>
        {step !== STEPS.SECRET && (
          <>
            <h2 className={styles.idwall__welcome}>
              {step === STEPS.INITIAL
                ? "Bem-vindo à D.Filipa"
                : step === STEPS.PERSONAL_INFO
                ? "Seus dados pessoais e de acesso"
                : "Informe seu endereço"}
            </h2>
            <div className={styles.idwall__progress}>
              <div className={styles.idwall__progress_bar}>
                <div
                  className={styles.idwall__progress_bar_fill}
                  style={{ width: `${getProgressPercentage()}%` }}
                />
              </div>
              <div className={styles.idwall__progress_label}>
                Passo {step} de {Object.keys(STEPS).length - 1}
              </div>
            </div>
          </>
        )}

        {step === STEPS.SECRET ? (
          renderStepContent()
        ) : (
          <form
            className={styles.idwall__form}
            onSubmit={handleSubmit(handleStepSubmit)}
          >
            {renderStepContent()}
            <div className={styles.idwall__buttons}>
              <Button
                title={
                  step === STEPS.ADDRESS ? "CONFIRMAR CADASTRO" : "PRÓXIMO"
                }
                loading={loading}
              />
              {step !== STEPS.INITIAL && (
                <Link
                  href="#"
                  className={styles.idwall__back_link}
                  onClick={(e) => {
                    e.preventDefault();
                    handleBack();
                  }}
                >
                  VOLTAR
                </Link>
              )}
            </div>
          </form>
        )}
      </div>
    </div>
  );
}
